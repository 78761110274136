export function toArray<Type>(maybeJobs: Type | Type[]): Type[] {
    return Array.isArray(maybeJobs) ? maybeJobs : [maybeJobs];
}


export function downloadUri(uri: string) {
    const link = document.createElement('a');
    link.href = uri;
    link.target = "_blank";
    link.setAttribute("download", "");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export function downloadUris(uris: string[]) {
   uris.forEach(uri => {
        const link = document.createElement('a');
        link.href = uri;
        link.target = "_blank";
        link.setAttribute("download", "");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    })
}

export function arrayUnique(array: any[]) {
    var a = array.concat();
    for (var i = 0; i < a.length; ++i) {
        for (var j = i + 1; j < a.length; ++j) {
            if (a[i] === a[j])
                a.splice(j--, 1);
        }
    }

    return a;
}

export function exportToJson(jsonString: string, fileName: string) {
    if (jsonString === undefined) return;
    if (fileName === undefined) fileName = "data.json";
    const link = document.createElement("a");
    link.href = `data:text/json;chatset=utf-8,${encodeURIComponent(
        jsonString
      )}`;
    link.download = fileName;
    link.click();
};

