import { BrowserRouter,Navigate,Route,Routes} from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import theme from './utils/Theme';
import { SnackbarProvider } from 'notistack';
import { ConfigLoader } from './components/ConfigLoader';
import { Authenticate } from './components/Authenticate';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CssBaseline from '@mui/material/CssBaseline';
import SubmitJob from './components/SubmitJob';
import DrawerList from './components/DrawerList';
import Dashboard from './components/Dashboard';
import CopyToken from './components/CopyToken';
import CheckUserAccess from './components/CheckUserAccess';
import JobsOverview from './components/JobsOverview';
import Clusters from './components/Clusters';
import Limits from './components/Limits';
import { Stack } from '@mui/material';
import JobDetails from './components/JobDetails';
import ClusterDetails from './components/ClusterDetails'
import Statistics from './components/Statistics';
import Reporting from './components/Reporting';
import Profitability from './components/Profitability';
import StartTimeDelay from './components/StartTimeDelay';
import InvalidJobsFromBatch from './components/InvalidJobsFromPools';
import ClearDLQMessages from './components/DLQMessageCleanUp';
import AppHeader from './components/AppHeader';
import { styled } from '@mui/system';


const StyledDivRoot = styled('div')(()=> ({
  flexGrow: 1,
}))

const StyledDivContent = styled('div')(()=> ({
  flexGrow: 1,
  height: '100%',
  width:"80%"
}))

const StyledDivContentRoot = styled('div')(()=> ({
  display: 'flex',
  width: '100%',
}))

export default function App(){
    
        return (
          <ThemeProvider theme={theme}>
          <ConfigLoader>
            <Authenticate>
              <SnackbarProvider maxSnack={3}>
                <StyledEngineProvider injectFirst>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <BrowserRouter >
                     <CssBaseline />
                      <StyledDivRoot >
                        <Stack>
                          <AppHeader/>
                          <Stack direction="row" >
                            <StyledDivContentRoot>
                                <DrawerList />
                                <StyledDivContent>
                                    <Routes>
                                        <Route path='/' element={<Navigate to='/admin/dashboard'/>} />
                                        <Route path='/admin/dashboard' element={<Dashboard/>} />
                                        <Route path='/admin/jobs/:id' element={<JobDetails/>} />
                                        <Route path='/admin/jobs' element={<JobsOverview  {...{ pageSize : 20, pageSizeOptions : [10, 20, 30]}}/>} />
                                        <Route path='/admin/clusters/:location/:name' element={<ClusterDetails/>} />
                                        <Route path='/admin/clusters' element={<Clusters/>} />
                                        <Route path='/admin/limits' element={<Limits/>} />
                                        <Route path='/admin/tools/copytoken' element={<CopyToken/>} />
                                        <Route path='/admin/tools/submitjob' element={<SubmitJob/>} />
                                        <Route path='/admin/tools/checkuseraccess' element={<CheckUserAccess/>} />
                                        <Route path='/admin/statistics' element={<Statistics/>} />
                                        <Route path='/admin/billing/reporting' element={<Reporting {...{ pageSize : 20, pageSizeOptions : [10, 20, 30]}}/>} />
                                        <Route path='/admin/billing/profitability' element={<Profitability  {...{ pageSize : 20, pageSizeOptions : [10, 20, 30]}}/>} />
                                        <Route path='/admin/billing/starttimedelay' element={<StartTimeDelay/>} />
                                        <Route path='/admin/azureResources/invalidjobsfrompools' element={<InvalidJobsFromBatch />} />
                                        <Route path='/admin/azureResources/dlqmessages' element={<ClearDLQMessages />} />
                                    </Routes>
                                </StyledDivContent>
                            </StyledDivContentRoot>
                          </Stack>
                        </Stack>
                      </StyledDivRoot> 
                    </BrowserRouter>
                  </LocalizationProvider>  
                </StyledEngineProvider>
              </SnackbarProvider>
            </Authenticate>
          </ConfigLoader>
        </ThemeProvider>
    );
}
