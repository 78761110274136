import * as React from 'react';
import MaterialTable from "@material-table/core";
import moment from 'moment'
import { IJob, useJobActions ,useJobReport} from '../Model/Job';
import { createDateColumn, toISOWithReducedPrecision } from '../utils/DateUtils';
import useInterval from '../utils/UseInterval';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { JobLink } from './JobsOverview';
import Progress from '../utils/ProgressUtils';
import HoursTimespanSelector, { HoursTimespan } from './HoursTimespanSelector';
import { EmailEllipsis } from './CompletedJobsFilter';


interface ISetAutoRefreshProps{
    toggleTabRefresh: boolean
}

export default function ActiveJobs(props:ISetAutoRefreshProps) {
    const jobReport = useJobReport('state=Active');
    const jobActions = useJobActions();
    const [autoRefresh, setAutoRefresh] = React.useState(props.toggleTabRefresh);
    
    
    React.useEffect(() => {
        if(autoRefresh===false ) //Trick to get refresh just by one variable on tab change
        {
            jobReport.refresh();
        }
        setAutoRefresh(props.toggleTabRefresh)
    },[props.toggleTabRefresh]);

    function onTimespanChanged(newTimespan: HoursTimespan) {
        jobReport.SetHoursTimespan(newTimespan);
    }

    function toggleAutoRefresh() {
        if (autoRefresh) {
            setAutoRefresh(false);
        }
        else {
            setAutoRefresh(true);
            jobReport.refresh()
        }
    }

    function getEstimatedUnits(job: IJob): string {
        if (job.executionInformation?.inputInformation == null)
            return "-";
        const inputInfo = job.executionInformation?.inputInformation;
        const units = inputInfo.gigaPixelsAT + inputInfo.megaPoints;
        return (Math.round(units * 100) / 100.).toString();
    }

    useInterval(() => {
        if (jobReport.error) {
            setAutoRefresh(false);
            return;
        }

        jobReport.refresh()
    }, autoRefresh ? 10000 : null);

    
    return (
        <div >
            <MaterialTable<IJob>
                title={<HoursTimespanSelector default={jobReport.hoursTimeSpan}  onValueChanged={onTimespanChanged} />}
                isLoading={jobReport.fetchCount === 0} // Avoid flicker on auto refresh and show loading only on first attempt
                columns={[
                    { title: "Progress", field: "id", render: rowData => (<Progress jobId={rowData.id} autoRefresh={autoRefresh} />), sorting: false, filtering: false, editable: "never" },
                    { title: "Name", field: "name", sorting: true, render: rowData => <JobLink job={rowData} /> },
                    { title: "Type", field: "type", sorting: true},
                    { title: "Email", field: "userDetails.email" as any, sorting: true,cellStyle: { width: '150px',maxWidth:'150px'}, headerStyle:{width:'150px',maxWidth:'150px'},render: rowData => <EmailEllipsis Email={rowData.userDetails.email}/>},
                    createDateColumn("Submission", "submissionDetails.time", 'desc'),
                    createDateColumn("Start", "executionInformation.startTime"),
                    { title: "Location", field: "dataCenter.location", sorting: true },
                    { title: "Size", field: "executionInformation.inputInformation", render: rowData => getEstimatedUnits(rowData), sorting: true, filtering: false, editable: "never" },
                ]}
                data={jobReport.data ?? []}
                actions={[
                    {
                        icon: () => autoRefresh ? <PauseCircleOutlineIcon /> : <PlayCircleOutlineIcon />,
                        tooltip: 'Auto Refresh',
                        isFreeAction: true,
                        onClick: () => toggleAutoRefresh()
                    },
                    rowData => ({
                        tooltip: 'Download logs',
                        icon: 'get_app',
                        disabled: rowData.state === "New",
                        onClick: (_, rowData) => jobActions.downloadLogs(rowData)
                    }),
                    rowData => ({
                        tooltip: 'Terminate Job',
                        icon: 'cancel',
                        disabled: rowData.state !== "Active",
                        onClick: (_, rowData) => jobActions.terminate(rowData)
                    }),

                ]}
                options={{
                    filtering: false,
                    pageSize: 10,
                    search: true,
                    sorting: true,
                    headerStyle: { backgroundColor: jobReport.error ? '#FF0000' : '#9BA5AE', color: '#000000' },
                    padding: 'dense',
                    maxBodyHeight: "63vh",
                    rowStyle: {
                        fontSize: "0.875rem",
                      }
                }}
            />
        </div>
    );

}


