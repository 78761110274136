import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select,{SelectChangeEvent} from '@mui/material/Select';
import { styled } from '@mui/system';


const StyledFormControl = styled(FormControl)((props)=> ({
    marginLeft: props.theme.spacing(2),
    minWidth: 120,
    backgroundColor: props.theme.palette.background.paper,
}))

const StyledSelect = styled(Select)((props)=> ({
    backgroundColor: props.theme.palette.background.paper,
    color: props.theme.palette.text.secondary,
}))



export enum HoursTimespan {
    LastHour = 1,
    Last6Hours = 6,
    Last24Hours = 24,
    LastWeek = 7 * 24,
    Last2Weeks = 2 * 7 * 24,
    LastMonth = 31 * 24,
    LastQuarter = 3 * 31 * 24,
    Last2Quarters = 6 * 31 * 24,
}
interface IHoursTimespanSelectorProps {
    default: HoursTimespan,
    onValueChanged: any,
    label?: string
}

export default function HoursTimespanSelector(props: IHoursTimespanSelectorProps) {

    const [timespan, setTimespan] = React.useState<HoursTimespan>(props.default);
    const [open, setOpen] = React.useState(false);

    function handleChange(event: SelectChangeEvent<unknown>) {
        setTimespan(event.target.value as unknown as number);
        // must use value directly because 'timespan' state will be updated asynchrononsly
        props.onValueChanged(event.target.value as unknown as number);
    }

    function handleClose() {
        setOpen(false);
    }

    function handleOpen() {
        setOpen(true);
    }

    return (
        <>
            <form autoComplete="off">
                {props.label}
                <StyledFormControl variant="standard">
                    <StyledSelect
                        open={open}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        value={timespan.toString()}
                        onChange={handleChange}
                        inputProps={{
                            name: "timespan",
                        }}
                    >
                        <MenuItem value={HoursTimespan.LastHour}>
                            Last hour
                        </MenuItem>
                        <MenuItem value={HoursTimespan.Last6Hours}>
                            Last 6 hours
                        </MenuItem>
                        <MenuItem value={HoursTimespan.Last24Hours}>
                            Last 24 hours
                        </MenuItem>
                        <MenuItem value={HoursTimespan.LastWeek}>
                            Last week
                        </MenuItem>
                        <MenuItem value={HoursTimespan.Last2Weeks}>
                            Last 2 weeks
                        </MenuItem>
                        <MenuItem value={HoursTimespan.LastMonth}>
                            Last month
                        </MenuItem>
                        <MenuItem value={HoursTimespan.LastQuarter}>
                            Last quarter
                        </MenuItem>
                        <MenuItem value={HoursTimespan.Last2Quarters}>
                            Last 2 quarters
                        </MenuItem>
                    </StyledSelect>
                </StyledFormControl>
            </form>
        </>
    );
}
