import * as React from 'react';
import { useSnackbar } from 'notistack';
import { toArray, downloadUri } from '../utils/Utils';
import { HoursTimespan } from '../components/HoursTimespanSelector';
import { dateToISOWithReducedPrecision } from '../utils/DateUtils';
import moment from 'moment';
import { useFetchApi } from '../utils/UseFetchApi';

export interface IInputInformation {
    photoCount: number;
    gigaPixelsAT: number;
    gigaPixelsRecons: number;
    pointCloudCount: number;
    megaPoints: number;
    megaPointsRecons: number;
    calibrationFromCache: boolean;
    reconstructionFromCache: boolean;
}

export interface IExecutionInformation {
    outcome?: string;
    startTime?: string;
    endTime?: string;
    computeTime?: number;
    exitCode?: number;
    inputInformation?: IInputInformation;
    estimatedUnits?: number;
}

export interface ISubmissionDetails {
    time?: string;
    userAgent?: string;
    clusterId?: string;
}

export interface IJobSettings {
    meshQuality?: string;
    outputs?: IOutputDetails[];
    processingEngines?: number;
}

export interface IOutputDetails {
    id?: string;
    format?: string;
    visibility?: string;
}

export interface IInputDetails {
    id?: string;
    type?: string;
    description?: string;
}

export interface IUserDetails {
    id: string;
    email: string;
}
export interface IDataCenter {
    location: string;
    id: string;
}
export interface IJob {
    id: string;
    projectId: string;
    name: string;
    state: string;
    connectProjectId: string;
    type: string;
    ultimateSite: string;
    executionInformation?: IExecutionInformation;
    submissionDetails?: ISubmissionDetails;
    inputs?: IInputDetails[];
    settings?: IJobSettings;
    userDetails: IUserDetails;
    creationTime: string;
    dataCenter: IDataCenter;
}

export function useJobReport(queryParams?: string) {
    const sevenDaysMilliseconds = 7 * 24 * 60 * 60 * 1000;
    const [hoursTimeSpan, setHoursTimeSpan] = React.useState<HoursTimespan>(HoursTimespan.LastWeek);
    const [timespan, SetTimespan] = React.useState({
        startDate: new Date(Date.now() - sevenDaysMilliseconds),
        endDate: new Date()
    });
    const fetchApi = useFetchApi<IJob[]>(getUrl(timespan.startDate, timespan.endDate));

    function getJobTimeQueryFilter(startDate: Date, endDate: Date): string {
        return `startTime=${dateToISOWithReducedPrecision(startDate)}` +
            `&endTime=${dateToISOWithReducedPrecision(endDate)}`;
    }

    function getQueryFilter(startDate: Date, endDate: Date): string {
        return queryParams ? getJobTimeQueryFilter(startDate, endDate) + `&${queryParams}` :
            getJobTimeQueryFilter(startDate, endDate);
    }

    function getUrl(startDate: Date, endDate: Date) {
        return window.location.origin + '/api/v2/jobs/report?' + getQueryFilter(startDate, endDate);
    }

    function getTimespanUrl(timeSpan: HoursTimespan) {
        var startDate: Date = new Date(moment().subtract(timeSpan as number, 'hours').toDate());
        var endDate: Date = new Date(moment().toDate());
        SetTimespan({
            startDate: startDate,
            endDate: endDate
        })
        return window.location.origin + '/api/v2/jobs/report?' + getQueryFilter(startDate, endDate);
    }

    function refresh() {
        fetchApi.run()
    }

    function handleTimespanChange(newSpan: { startDate: Date, endDate: Date }) {
        SetTimespan(newSpan);
        fetchApi.run(getUrl(newSpan.startDate, newSpan.endDate));
    }

    function handleHoursTimespanChange(newSpan: HoursTimespan) {
        setHoursTimeSpan(newSpan);
        fetchApi.run(getTimespanUrl(newSpan));
    }

    // run once at startup
    React.useEffect(() => {
        fetchApi.run();
    }, []);

    return {
        data: fetchApi.data,
        hasData: fetchApi.hasData,
        refresh,
        isFetching: fetchApi.isFetching,
        fetchCount: fetchApi.fetchCount,
        timespan,
        SetTimespan: handleTimespanChange,
        hoursTimeSpan,
        SetHoursTimespan: handleHoursTimespanChange,
        error: fetchApi.error
    }
}

export function useJobActions() {
    const { enqueueSnackbar } = useSnackbar();
    const terminateApi = useFetchApi<IJob>();
    const downloadApi = useFetchApi<any>();

    function terminate(maybeJobs: IJob[] | IJob) {
        toArray(maybeJobs).forEach(job => {
            var result = window.confirm(`Are you sure you want to kill '${job.name}' id: ${job.id} that belong to ${job.userDetails.email} ?"`);
            if (!result)
                return;

            enqueueSnackbar(`Killing '${job.name}' id: ${job.id}...`, { variant: "info" });

            terminateApi.run(window.location.origin + "/api/v2/jobs/" + job.id + "/terminate",
                {
                    method: 'POST'
                })
                .then(result =>
                    enqueueSnackbar(`Job '${result?.name}' killed!`, { variant: "success" }))
                .catch(error => {
                    enqueueSnackbar(`Failed to kill '${job.name}' : ${error.toString()}`, { variant: "error" });
                });
        });
    }

    function downloadLogs(maybeJobs: IJob[] | IJob) {
        toArray(maybeJobs).forEach(job => {
            downloadApi.run(window.location.origin + "/api/v2/jobs/" + job.id + "/logsaccess")
                .then(result => {
                    if (!result?.uri) {
                        enqueueSnackbar(`Failed to download logs of '${job.name}'`);
                        return;
                    }
                    downloadUri(result.uri);
                })
                .catch(error => {
                    enqueueSnackbar(`Failed to download '${job.name}' : ${error.toString()}`, { variant: "error" });
                })
        });
    }

    return {
        terminate,
        downloadLogs
    }
}
