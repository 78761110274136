import * as React from 'react';
import MaterialTable from "@material-table/core";
import { toArray } from '../utils/Utils';
import { useFetchApi } from '../utils/UseFetchApi'
import { ClusterLink, clusterHasTrouble } from './ClusterDetails'
import { enqueueSnackbar } from 'notistack';

interface IJobStatistics {
    active: number;
    completed: number;
}

enum ClusterState {
    "Steady",
    "Resizing",
    "Stopping"
}
interface INodeStatistics {
    idle: number;
    running: number;
    starting: number;
    rebooting: number;
    start_task: number;
    start_task_failed: number;
    leaving: number;
    unusable: number;
    preempted: number;
    other: number;
}

export interface IClusterInfo {
    name: string;
    dedicatedNodes: number;
    lowPriorityNodes: number;
    state: string;
    location: string;
    jobStatistics: IJobStatistics;
    nodeStatistics: INodeStatistics;
}

export default function Clusters() {
    const clustersApi = useFetchApi<IClusterInfo[]>(window.location.origin + "/api/v2/clusters");
    const fetchApi = useFetchApi<any>()

    function onDeleteCluster(clusters: IClusterInfo[]) {
        clusters.forEach(cluster => {
            if (!window.confirm("Are you sure you want to delete the cluster: " + cluster.name + " ?")) {
                return;
            }

            if (clustersApi.data && clustersApi.data.length < 2 &&
                !window.confirm("Are you REALLY sure you want to delete the last cluster?")) {
                return;
            }
            enqueueSnackbar(`Deletion will take some time to complete. Please refresh after sometime`,{ variant: "info" });
            fetchApi.run(window.location.origin + "/api/v2/clusters/locations/" + cluster.location + "/" + cluster.name,
                {
                    method: 'DELETE'
                })
                .then(() => { clustersApi.run(); });
        });
    }

    React.useEffect(() => {
        clustersApi.run();
    }, []);

    function getTrouble(cluster: IClusterInfo)
    {
        if (clusterHasTrouble(cluster))
            return "⚠️"
        return "✔️"
    }

    const pageSize = 10, pageSizeOptions = [5, 10, 20];

    return (
        <div>
            <MaterialTable<IClusterInfo>
                title="Clusters"
                isLoading={clustersApi.isFetching}
                columns={[
                    { title: "Name", field: "name", sorting: true, render: rowData => <ClusterLink cluster={rowData} />  },
                    { title: "Location", field: "location", },
                    {
                        title: "Total nodes (low priority)", field: "lowPriorityNodes",
                        render: rowData => <div>{rowData.dedicatedNodes + rowData.lowPriorityNodes} ({rowData.lowPriorityNodes})</div>
                    },
                    { title: "Jobs Active", field: "jobStatistics.active" as any },
                    { title: "Jobs Completed", field: "jobStatistics.completed" },
                    { title: "State", field: "state", },
                    {
                        title: "Trouble?", field: "trouble",
                        render: rowData => <div>{getTrouble(rowData)}</div>,
                        sorting: false
                    }
                ]}
                data={clustersApi.data ?? []}
                actions={[
                    {
                        tooltip: 'Refresh',
                        icon: 'refresh',
                        isFreeAction: true,
                        onClick: () => clustersApi.run(),
                    },
                    rowData => ({
                        tooltip: 'Delete cluster',
                        icon: 'delete',
                        disabled: rowData.state.toLowerCase() !== ClusterState[ClusterState.Steady].toLowerCase(),
                        onClick: (event, rowData) => onDeleteCluster(toArray(rowData))
                    })
                ]}
                options={{
                    actionsColumnIndex: -1,
                    search: true,
                    sorting: true,
                    pageSize: pageSize,
                    pageSizeOptions: pageSizeOptions,
                    headerStyle: { backgroundColor: '#9BA5AE', color: '#000000' },
                    padding: 'dense'
                }}
            />
        </div>
    );
}
