import * as React from 'react';
import { Link } from "react-router-dom";
import { Box, ListItemButton, Paper } from '@mui/material';
import Dashboard from '@mui/icons-material/Dashboard';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import Cloud from '@mui/icons-material/Cloud';
import Work from '@mui/icons-material/Work';
import Build from '@mui/icons-material/Build';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Power from '@mui/icons-material/Power';
import Lock from '@mui/icons-material/Lock';
import FitnessCenter from '@mui/icons-material/FitnessCenter';
import Drawer from '@mui/material/Drawer';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import IconButton from '@mui/material/IconButton';
import { Settings, Assessment, CancelOutlined } from '@mui/icons-material';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import AttachMoney from '@mui/icons-material/AttachMoney';
import TrendingUp from '@mui/icons-material/TrendingUp';
import Timer from '@mui/icons-material/Timer';
import DeleteIcon from '@mui/icons-material/Delete';
import Devices from '@mui/icons-material/Devices';
import CancelOutlineIcon from '@mui/icons-material/CancelOutlined';
import { styled } from '@mui/system';


export interface IDrawerState {
    opened: boolean;
    toolsOpen: boolean;
    billingOpen: boolean;
    azureResourcesOpen: boolean;
}


const StyledDrawerPaper = styled(Paper)(()=> ({
    position: "relative" as 'relative',
    whiteSpace: 'nowrap' as 'nowrap',
}))

const StyledDiv = styled('div')(()=> ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
}))

const StyledList = styled(List)((props)=> ({
    width: '100%',
    backgroundColor: props.theme.palette.background.paper,
    textDecoration: 'none',
    height:'calc(100vh - 98px)'
}))

const StyledListItemIcon = styled(ListItemIcon)((props)=> ({
    minWidth: 0
}))

const StyledLink = styled(Link)(()=> ({
    textDecoration: 'none',
    color: 'inherit'
}))

const StyledListItem = styled(ListItemButton)((props)=> ({
    paddingLeft: props.theme.spacing(4),
}))

const StyledListItemText = styled(ListItemText)((props)=> ({
    paddingLeft: props.theme.spacing(1)
}))


export default function DrawerList () {


    const [opened,setOpened]=React.useState(true);
    const [toolsOpen,setToolsOpen]=React.useState(false);
    const [billingOpen,setBillingOpen]=React.useState(false);
    const [azureResourcesOpen,setAzureResourcesOpen]=React.useState(false);

    const handleToolsClicked = () => {
        setToolsOpen(!toolsOpen);
    };

    const handleBillingClicked = () => {
        setBillingOpen(!billingOpen);
    };

    const handleDrawerClick = () => {
        setOpened(!opened);
    };

    const handleAzureResourcesClicked = () => {
        setAzureResourcesOpen(!azureResourcesOpen);
    }

    function renderItemText(name: string) {
        return(
            opened &&
            <Box display={{ xs: 'none', sm: 'block' }}>
                <StyledListItemText primary={name} /></Box>);
    }

    function renderLinkItem(link: string, iconName: any, name: string) {
        return(
            <StyledLink to={link}>
                <ListItemButton>
                    <StyledListItemIcon>
                        {iconName}
                    </StyledListItemIcon>
                    {renderItemText(name)}
                </ListItemButton>
            </StyledLink>);
    }

    return (
            <StyledDrawerPaper 
                variant="elevation"
                sx={{overflowX : opened ? 'hidden' : ""}}
            >
                <StyledDiv>
                    <IconButton onClick={ handleDrawerClick} size='small'>
                        { opened ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </StyledDiv>
                <StyledList>
                    { renderLinkItem("/admin/dashboard", <Dashboard />, "Dashboard")}
                    { renderLinkItem("/admin/clusters", <Cloud />, "Clusters")}
                    { renderLinkItem("/admin/jobs", <Work />, "Jobs")}
                    { renderLinkItem("/admin/limits", <Settings />, "Limits")}
                    { renderLinkItem("/admin/statistics", <EqualizerIcon />, "Statistics")}
                    <ListItemButton onClick={ handleToolsClicked}>
                        <StyledListItemIcon>
                            <Build />
                        </StyledListItemIcon>
                        { renderItemText("Tools")}
                        { opened && ( toolsOpen ?
                            <Box display={{ xs: 'none', sm: 'block' }}><ExpandLess /></Box> :
                            <Box display={{ xs: 'none', sm: 'block' }}><ExpandMore /></Box>)}
                    </ListItemButton>
                    <Collapse in={ toolsOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <StyledLink to="/admin/tools/submitjob" >
                                <StyledListItem>
                                    <StyledListItemIcon>
                                        <FitnessCenter />
                                    </StyledListItemIcon>
                                    { opened &&
                                        <Box display={{ xs: 'none', sm: 'block' }}>
                                            <StyledListItemText primary="Submit Jobs" />
                                        </Box>}
                                </StyledListItem>
                            </StyledLink>
                            <StyledLink to="/admin/tools/copytoken" >
                                <StyledListItem>
                                    <StyledListItemIcon>
                                        <Power />
                                    </StyledListItemIcon>
                                    { opened &&
                                        <Box display={{ xs: 'none', sm: 'block' }}>
                                            <StyledListItemText primary="Copy Token" />
                                        </Box>}
                                </StyledListItem>
                            </StyledLink>
                            <StyledLink to="/admin/tools/checkuseraccess">
                                <StyledListItem >
                                    <StyledListItemIcon >
                                        <Lock />
                                    </StyledListItemIcon>
                                    { opened &&
                                        <Box display={{ xs: 'none', sm: 'block' }}>
                                            <StyledListItemText primary="Check User Access" />
                                        </Box>}
                                </StyledListItem>
                            </StyledLink>
                        </List>
                    </Collapse>
                    <ListItemButton onClick={ handleBillingClicked}>
                        <StyledListItemIcon>
                            <AttachMoney />
                        </StyledListItemIcon>
                        { renderItemText("Billing")}
                        { opened && ( billingOpen ?
                            <Box display={{ xs: 'none', sm: 'block' }}><ExpandLess /></Box> :
                            <Box display={{ xs: 'none', sm: 'block' }}><ExpandMore /></Box>)}
                    </ListItemButton>
                    <Collapse in={ billingOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <StyledLink to="/admin/billing/reporting">
                                <StyledListItem>
                                    <StyledListItemIcon >
                                        <Assessment />
                                    </StyledListItemIcon>
                                    { opened &&
                                        <Box display={{ xs: 'none', sm: 'block' }}>
                                            <StyledListItemText primary="Reporting" />
                                        </Box>}
                                </StyledListItem>
                            </StyledLink>
                            <StyledLink to="/admin/billing/profitability">
                                <StyledListItem >
                                    <StyledListItemIcon >
                                        <TrendingUp />
                                    </StyledListItemIcon>
                                    { opened &&
                                        <Box display={{ xs: 'none', sm: 'block' }}>
                                            <StyledListItemText primary="Profitability" />
                                        </Box>}
                                </StyledListItem>
                            </StyledLink>
                            <StyledLink to="/admin/billing/starttimedelay">
                                <StyledListItem>
                                    <StyledListItemIcon>
                                        <Timer />
                                    </StyledListItemIcon>
                                    { opened &&
                                        <Box display={{ xs: 'none', sm: 'block' }}>
                                            <StyledListItemText primary="Start Time Delay" />
                                        </Box>}
                                </StyledListItem>
                            </StyledLink>
                        </List>
                    </Collapse>
                    <ListItemButton onClick={ handleAzureResourcesClicked}>
                        <StyledListItemIcon>
                            <Devices />
                        </StyledListItemIcon>
                        { renderItemText("Azure Resources")}
                        { opened && ( azureResourcesOpen ?
                            <Box display={{ xs: 'none', sm: 'block' }}><ExpandLess /></Box> :
                            <Box display={{ xs: 'none', sm: 'block' }}><ExpandMore /></Box>)}
                    </ListItemButton>
                    <Collapse in={ azureResourcesOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <StyledLink to="/admin/azureResources/dlqmessages">
                                <StyledListItem>
                                    <StyledListItemIcon>
                                        <DeleteIcon />
                                    </StyledListItemIcon>
                                    { opened &&
                                        <Box display={{ xs: 'none', sm: 'block' }}>
                                            <StyledListItemText primary="DLQ Messages" />
                                        </Box>}
                                </StyledListItem>
                            </StyledLink>
                            <StyledLink to="/admin/azureResources/invalidjobsfrompools" >
                                <StyledListItem >
                                    <StyledListItemIcon>
                                        <CancelOutlineIcon />
                                    </StyledListItemIcon>
                                    { opened &&
                                        <Box display={{ xs: 'none', sm: 'block' }}>
                                            <StyledListItemText primary="Invalid Jobs" />
                                        </Box>}
                                </StyledListItem>
                            </StyledLink>
                        </List>
                    </Collapse>
                </StyledList>
            </StyledDrawerPaper >
        );
}


