import React from 'react';
import {
    ComposableMap,
    ZoomableGroup,
    Geographies,
    Geography,
    Marker,
} from 'react-simple-maps';
import { Paper, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { useFetchApi } from '../utils/UseFetchApi';
import { IClusterInfo } from './Clusters';
import { clusterHasTrouble } from './ClusterDetails';
import { Theme } from '@mui/material/styles';
import { Tooltip } from '@mui/material';
import { styled } from '@mui/system';

const wrapperStyles = {
    width: "100%",
    maxWidth: 900,
    margin: "0 auto",
}

export interface ICoordinate {
    longitude: number;
    latitude: number;
}

export interface IClusterLocation {
    location: string;
    id: string;
    coordinate: ICoordinate;
}



const StyledPaper = styled(Paper)((props)=> ({
    padding: props.theme.spacing(1),
    textAlign: 'center',
    color: props.theme.palette.text.secondary,
    height: "100%",
}))


export default function ClusterMap() {
    const clustersInfo = useFetchApi<IClusterInfo[]>(window.location.origin + "/api/v2/clusters");
    const clustersLocation = useFetchApi<IClusterLocation[]>(window.location.origin + "/api/v2/clusters/locations");
    const history = useNavigate();

    function handleClick(marker: string) {
        history('/admin/clusters');
        console.log("Marker data: ", marker);
    }

    function getClusterColor(location: string)
    {
        if (clustersInfo.data && clustersInfo.data.find(cluster => cluster.location === location))
        {
            var loc_clusters = clustersInfo.data.filter(cluster => cluster.location === location);
            if (loc_clusters.some(c => clusterHasTrouble(c)))
                return "#fcdf02";
            return "#67CC22";
        }
        return "#FF0000";
    }

    React.useEffect(() => {
        clustersInfo.run();
        clustersLocation.run();
    }, []);

    return (
        <StyledPaper>
            <Typography align="left" sx={{ fontWeight: 500 }}>Clusters</Typography>
            <div style={wrapperStyles}>
                
                <ComposableMap
                    projectionConfig={{
                        scale: 150
                    }}
                    style={{
                        width: "100%",
                        maxHeight: "300px"
                    }}>
                    <ZoomableGroup center={[20, 10]} zoom={1.15} minZoom={1.15} maxZoom={3} >
                        <Geographies geography="/world-continents.json">
                            {({ geographies }) =>
                                geographies.map(
                                    (geography: any, i: number) => geography.id !== "ATA" &&
                                    (
                                        <Geography
                                            key={i}
                                            geography={geography}
                                            style={{
                                                default: {
                                                    fill: "#ECEFF1",
                                                    stroke: "#607D8B",
                                                    strokeWidth: 0.75,
                                                    outline: "none",
                                                    cursor: 'grab'
                                                },
                                                hover: {
                                                    fill: "#ECEFF1",
                                                    stroke: "#607D8B",
                                                    strokeWidth: 0.75,
                                                    outline: "none",
                                                    cursor: 'grab'
                                                },
                                                pressed: {
                                                    fill: "#ECEFF1",
                                                    stroke: "#607D8B",
                                                    strokeWidth: 0.75,
                                                    outline: "none",
                                                },
                                            }} />
                                    ))}
                        </Geographies>
                        {clustersLocation.data && clustersLocation.data.map((dataCenter: IClusterLocation, i: number) => (
                            <Marker
                                key={i}
                                coordinates={[dataCenter.coordinate.longitude, dataCenter.coordinate.latitude]}
                                onClick={() => handleClick(dataCenter.location)}
                                style={{
                                    default: { fill: "#EEF6E8" },
                                    hover: { fill: getClusterColor(dataCenter.location) },
                                    pressed: { fill: getClusterColor(dataCenter.location) }
                                }}>
                                    <Tooltip title={dataCenter.location} placement="top" arrow>
                                    <circle
                                        cx={0}
                                        cy={0}
                                        r={5}
                                        style={{
                                            fill: getClusterColor(dataCenter.location),
                                            stroke: "#464747",
                                            strokeWidth: 0.75,
                                            opacity: 0.9,
                                            cursor: 'pointer'
                                        }} />
                                    </Tooltip>
                            </Marker>
                        ))}
                    </ZoomableGroup>
                </ComposableMap>
            </div>
        </StyledPaper>
    );
}
