import { HoursTimespan } from "./HoursTimespanSelector";
import { JobTypeOutcomeStatistics as JobTypeOutcomeStats } from "realityservices-ui-components";

export const timeOptions = [
    { value: HoursTimespan.LastHour, label: "Last hour" },
    { value: HoursTimespan.Last6Hours, label: "Last 6 hours" },
    { value: HoursTimespan.Last24Hours, label: "Last 24 hours" },
    { value: HoursTimespan.LastWeek, label: "Last week" },
    { value: HoursTimespan.Last2Weeks, label: "Last 2 weeks" },
    { value: HoursTimespan.LastMonth, label: "Last month" },
    { value: HoursTimespan.LastQuarter, label: "Last quarter" },
    { value: HoursTimespan.Last2Quarters, label: "Last 2 quarters" },
];

export default function JobTypeOutcomeStatistics() {
    return (
        <JobTypeOutcomeStats
            dataUrl={
                window.location.origin + "/api/v3/jobs/outcomeReportByType"
            }
            filterOptions={timeOptions}
            defaultFilterOption={{
                value: HoursTimespan.LastWeek,
                label: "Last week",
            }}
        />
    );
}
