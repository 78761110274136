import * as React from 'react';
import {
    Table,
    TableContainer,
    TableCell,
    TableRow,
    TableBody,
    Typography,
    LinearProgress,
    CircularProgress,
    Button,
} from '@mui/material';
import { green, red, grey, orange } from '@mui/material/colors';
import CheckCircleOutlineSharpIcon from '@mui/icons-material/CheckCircleOutlineSharp';
import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp';
import LoopSharpIcon from '@mui/icons-material/LoopSharp';
import PanToolSharpIcon from '@mui/icons-material/PanToolSharp'
import CancelOutlineIcon from '@mui/icons-material/CancelOutlined';
import GetAppSharpIcon from '@mui/icons-material/GetAppSharp';
import { useFetchApi } from '../utils/UseFetchApi';
import { IJob, IOutputDetails, IInputDetails, useJobActions } from '../Model/Job';
import CenteredProgress from './CenteredProgress';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useInterval from '../utils/UseInterval';
import { IJobSteps, IJobStep } from '../Model/JobSteps';
import JobStepDetails from './JobStepDetails';
import { dateStringToLocaleString } from '../utils/DateUtils';
import { downloadUri } from '../utils/Utils';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/system';



const StyledDivRoot = styled('div')((props)=> ({
    backgroundColor: props.theme.palette.background.paper,
}))

const StyledDivSpacing = styled('div')((props)=> ({
    padding: props.theme.spacing(1),
}))

const StyledTableCell = styled(TableCell)((props)=> ({
    border: '0px'
}))

const StyledTableRow = styled(TableRow)((props)=> ({
    border: '0px'
}))


const BorderLinearProgress = styled(LinearProgress)({
    height: 25,
    width: 600,
    borderRadius: 20,
  
    '& .MuiLinearProgress-bar': {
      borderRadius: 20,
    },
  });

enum ETypography {
    None,
    Standard,
    NoWrap,
}

interface IJobRow {
    label: string;
    fill: any;
    typography: ETypography;
}

interface SasUri {
    sasUri: string;
}

function JobInfoDisplay(props: { job: IJob }) {
    const jobStorageSas = useFetchApi<SasUri>(`${window.location.origin}/api/v2/jobs/${props.job.id}/sas-uri`);
    const jobStepsFetch = useFetchApi<IJobSteps>(`${window.location.origin}/api/v2/jobs/${props.job.id}/steps`);
    const runningTaskApi = useFetchApi<any>(`${window.location.origin}/api/v2/jobs/${props.job.id}/runningTasks`);
    const failedTaskApi = useFetchApi<any>(`${window.location.origin}/api/v2/jobs/${props.job.id}/failedTasks`);
    const [selected, setSelected] = React.useState<IJobStep | undefined>(undefined);
    const jobActions = useJobActions();

    const [runningTasksInfo, setRunningTasksInfo] = React.useState<string>("Not yet queried");
    const [failedTasksInfo, setFailedTasksInfo] = React.useState<string[] | string>("Not yet queried");

    React.useEffect(() => {
        jobStepsFetch.run();
        jobStorageSas.run();
    }, []);

    function DoFetch() {
        return !jobStepsFetch.data || jobStepsFetch.data.activeIndex !== -1;
    }

    function GetButtons(job: IJob) {
        var butLogs = (<Button variant="contained" color="primary" size="small"
            startIcon={<GetAppSharpIcon />} disableElevation onClick={(event) => handleDownloadJobLogs(event)}>
                Logs
            </Button>);
        var butKill = (<Button variant="contained" style={{color: grey[900], backgroundColor: orange[200]}} size="small"
            startIcon={<CancelOutlineIcon />} disableElevation onClick={() => jobActions.terminate(job)}>
                Terminate
            </Button>);
        if (job.state === "Completed")
            return butLogs;
        return (<div>{butLogs}&nbsp;{butKill}</div>)
    }

    useInterval(() => {
        if (jobStepsFetch.error) {
            return;
        }
        jobStepsFetch.run();
    }, DoFetch() ? 15000 : null);

    const handleClick = (event: React.MouseEvent<unknown>, step: IJobStep) => {
        setSelected(step);
    };

    const handleDownloadJobLogs = (event: React.MouseEvent<unknown>) => {
        jobActions.downloadLogs(props.job);
    };

    const handleDownloadTaskLogs = (taskId: string) => {
        const taskLog = `/${taskId}/$TaskLog/Task_${taskId}.log`;
        let uri = jobStorageSas?.data?.sasUri.replace("?", `${taskLog}?`) ? jobStorageSas?.data?.sasUri.replace("?", `${taskLog}?`) : "";
        downloadUri(uri);
    };

    const getRunningTasks = (event: React.MouseEvent<unknown>) => {
        runningTaskApi.run()
        .then(result => {
            if (result.length > 0)
                setRunningTasksInfo(`${result.length} tasks running: ${result.join(", ")}`);
            else
                setRunningTasksInfo("No running task");
        })
        .catch(error => {
            setRunningTasksInfo(`Failed to get running tasks for ${props.job.name}: ${error.toString()}`);
        });
    };

    const getFailedTasks = (event: React.MouseEvent<unknown>) => {
        failedTaskApi.run()
        .then(result => {
            if (result.length > 0)
                setFailedTasksInfo(result);
            else
                setFailedTasksInfo("No failed task");
        })
        .catch(error => {
            setFailedTasksInfo(`Failed to get failed tasks for ${props.job.name}: ${error.toString()}`);
        });
    };

    if (jobStepsFetch.isFetching && !jobStepsFetch.data)
        return (<CenteredProgress />);

    if (jobStepsFetch.error)
        return (<div>{`${jobStepsFetch.error}`}</div>);

    if (!jobStepsFetch.data)
        return (<div>{`Job(${props.job.id}) steps not found`}</div>);

    function createRow(jobRow: IJobRow)
    {
        let filling: any;
        switch (jobRow.typography)
        {
            case ETypography.None:
                filling = jobRow.fill;
                break;

            case ETypography.Standard:
                filling = <Typography>{jobRow.fill}</Typography>;
                break;

            case ETypography.NoWrap:
                filling= <Typography noWrap>{jobRow.fill}</Typography>;
                break;
        }

        return (
            <StyledTableRow >
                <StyledTableCell>
                    <strong>{jobRow.label}</strong>
                </StyledTableCell>
                {<StyledTableCell>
                    {filling}
                </StyledTableCell>}
            </StyledTableRow>
        );
    }

    function jobInfoHeader(job: IJob) {
        const jobInfoHeaderRows: IJobRow[] = [{ label: "Id:", fill: job.id, typography: ETypography.NoWrap },
            { label: "ProjectId:", fill: job.connectProjectId ?? "/", typography: ETypography.NoWrap },
            { label: "Type:", fill: job.type, typography: ETypography.NoWrap },
            { label: "State:", fill: job.state, typography: ETypography.NoWrap },
            { label: "Location:", fill: job.dataCenter.location, typography: ETypography.NoWrap },
            { label: "Outcome:", fill: job.executionInformation?.outcome ?? <CircularProgress size={24} />, typography: ETypography.NoWrap },
            { label: "Actions:", fill: GetButtons(job), typography: ETypography.None }];

        return (
            <Table size="small" style={{ width: "auto", tableLayout: "auto" }}>
                <TableBody>
                    <StyledTableRow>
                        <Typography variant="h6">
                            Job Details:
                        </Typography>
                    </StyledTableRow>
                    {jobInfoHeaderRows.map((jobInfoHeaderRow) => createRow(jobInfoHeaderRow))}
                </TableBody>
            </Table>
        )
    }

    function outputDetailsList(details: IOutputDetails[]) {
        var formats:string[] = [];
        for(var i = 0; i < details.length; i++) {
            formats.push(details[i].format || "Unknown");
        }
        return (
            <div>{formats.join(", ")}</div>
        );
    }

    function getDuration(timeInMilliseconds: number) {
        if (timeInMilliseconds === 0) {
            return (
                <CircularProgress size={16} />
            );
        }
        else {
            var minutes = Math.floor((timeInMilliseconds / (1000 * 60)) % 60),
                hours = Math.floor((timeInMilliseconds / (1000 * 60 * 60)) % 24),
                days = Math.floor((timeInMilliseconds / (1000 * 60 * 60 * 24)));

            var hoursString = (hours < 10) ? "0" + hours : hours;
            var minutesString = (minutes < 10) ? "0" + minutes : minutes;

            return (days + "d " + hoursString + "h " + minutesString + "m");
        }
    }

    function resultDetailsDisplay(job: IJob) {
        var startTime = job?.executionInformation?.startTime ? job?.executionInformation?.startTime : "0";
        var endTime = job?.executionInformation?.endTime ? job?.executionInformation?.endTime : "0";
        var computeTime = job?.executionInformation?.computeTime ?? ""
        var startTimeNum = Date.parse(startTime);
        var curEndTimeNum = endTime === "0" ? Date.now() : Date.parse(endTime);
        var runTime = curEndTimeNum - startTimeNum;
        if (startTime === "0" && endTime === "0")
            runTime = 0;

        var startTimeTrimmed = job.executionInformation?.startTime ? dateStringToLocaleString(job.executionInformation?.startTime) : "(not started)";
        var endTimeTrimmed = job.executionInformation?.endTime ? dateStringToLocaleString(job.executionInformation?.endTime) : "";

        const resultRows: IJobRow[] = [{label: "Start Time:", fill: startTimeTrimmed, typography: ETypography.Standard},
            {label: "End Time:", fill: endTimeTrimmed === "" ? <CircularProgress size={16} /> : endTimeTrimmed, typography: ETypography.Standard},
            { label: "Run Time:", fill: getDuration(runTime), typography: ETypography.Standard },
            { label: "Compute Time (s): ", fill: computeTime === "" ? <CircularProgress size={16} /> : computeTime, typography: ETypography.Standard },
            {label: "Exit Code:", fill: job?.executionInformation?.exitCode ?? <CircularProgress size={16} />, typography: ETypography.Standard}];

        return(
            <Table size="small" style={{ width: "auto", tableLayout: "auto" }}>
                <TableBody>
                    {resultRows.map((resultRow) => createRow(resultRow))}
                </TableBody>
            </Table>
        )
    }

    function inputDetailsList(details: IInputDetails[]) {
        const listItems = details.map((detail, index) =>
        {
            const inputRows: IJobRow[] = [{label: "Type:", fill: detail.type, typography: ETypography.NoWrap},
                {label: "Description:", fill: detail.description, typography: ETypography.NoWrap},
                {label: "Id:", fill: detail.id, typography: ETypography.NoWrap}];

            return (<Table size="small" style={{ width: "auto", tableLayout: "auto" }}>
                <TableBody>
                    <StyledTableRow>
                        <Typography variant="h6">
                            Input {index+1}:
                        </Typography>
                    </StyledTableRow>
                    {inputRows.map((inputRow) => createRow(inputRow))}
                </TableBody>
            </Table>);
        });
        return (
            <div>{listItems}</div>
        );
    }

    function jobSettingsDisplay(job: IJob) {
        const outputDetails: IOutputDetails[] = job.settings?.outputs ? job.settings?.outputs : [];
        const settingsRows: IJobRow[] = [{label: "Mesh Quality:", fill: job.settings?.meshQuality, typography: ETypography.NoWrap},
            {label: "Output Types:", fill: outputDetailsList(outputDetails), typography: ETypography.Standard},
            {label: "Processing Engines", fill: job.settings?.processingEngines, typography: ETypography.NoWrap}];
        return(
            <Table size="small" style={{ width: "auto", tableLayout: "auto" }}>
                <TableBody>
                    {settingsRows.map((settingsRow) => createRow(settingsRow))}
                </TableBody>
            </Table>
        )
    }

    function inputInfoDisplay(job: IJob) {
        if (job.executionInformation?.inputInformation == null)
            return;

        const inputInfo = job.executionInformation?.inputInformation;

        const photoRows: IJobRow[] = [{label: "Photo count:", fill: inputInfo.photoCount, typography: ETypography.NoWrap},
            {label: "Input GPix:", fill: inputInfo.gigaPixelsAT, typography: ETypography.NoWrap},
            {label: "Reconstruction GPix:", fill: inputInfo.gigaPixelsRecons, typography: ETypography.NoWrap}];

        const photoInfo = inputInfo.photoCount === 0 ? "" : (
        <TableBody>
            {photoRows.map((photoRow) => createRow(photoRow))}
        </TableBody>);

        const pointCloudRows: IJobRow[] = [{label: "Pointcloud count:", fill: inputInfo.pointCloudCount, typography: ETypography.NoWrap},
            {label: "Input Megapoints:", fill: inputInfo.megaPoints, typography: ETypography.NoWrap},
            {label: "Recons Megapoints:", fill: inputInfo.megaPointsRecons, typography: ETypography.NoWrap}];

        var pointCloudInfo = inputInfo.pointCloudCount === 0 ? "" : (
        <TableBody>
            {pointCloudRows.map((pointCloudRow) => createRow(pointCloudRow))}
        </TableBody>);

        return (
            <Table  size="small" style={{ width: "auto", tableLayout: "auto" }}>
                {photoInfo}
                {pointCloudInfo}
                <TableBody>
                    <StyledTableRow>
                        <StyledTableCell>
                            <strong>Cache:</strong>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Typography noWrap>
                                AT: {inputInfo.calibrationFromCache ? "true" : "false"} /
                                Reconstruction: {inputInfo.reconstructionFromCache ? "true" : "false"}
                            </Typography>
                        </StyledTableCell>
                    </StyledTableRow>
                </TableBody>
            </Table>
        );
    }

    function submissionDetailsDisplay(job: IJob) {
        var submissionTime = job.submissionDetails?.time ? job.submissionDetails?.time : "(no submission time)";
        var startTime = job.executionInformation?.startTime ? job.executionInformation?.startTime : "(not started)";
        var submissionTimeNum = Date.parse(startTime);
        var startTimeNum = Date.parse(submissionTime);
        var waitTime = submissionTimeNum - startTimeNum;
        if (startTime === "(not started)" || submissionTime === "(no submission time)")
            waitTime = 0;
        else {
            submissionTime = dateStringToLocaleString(submissionTime);
            startTime = dateStringToLocaleString(startTime);
        }

        const submissionDetailsRows: IJobRow[] = [{label: "User-Agent", fill: job.submissionDetails?.userAgent, typography: ETypography.Standard},
            {label: "Email:", fill: job.userDetails.email, typography: ETypography.NoWrap},
            {label: "Ultimate Site:", fill: job.ultimateSite, typography: ETypography.NoWrap},
            {label: "Submission Time:", fill: submissionTime, typography: ETypography.NoWrap},
            {label: "Start Time:", fill: startTime, typography: ETypography.NoWrap},
            {label: "Wait Time:", fill: waitTime === 0 ? "(not started)" : getDuration(waitTime), typography: ETypography.NoWrap}];

        return (
            <Table size="small" style={{ width: "auto", tableLayout: "auto" }}>
                <TableBody>
                    <StyledTableRow>
                        <Typography variant="h6">
                            Submission Details:
                        </Typography>
                    </StyledTableRow>
                    {submissionDetailsRows.map((submissionDetailsRow) => createRow(submissionDetailsRow))}
                </TableBody>
            </Table>
        )
    }

    function passCheckDisplay(job: IJob) {
        const outcome = job.executionInformation?.outcome ?? '/';
        if (outcome.toLowerCase() === "success")
            return <CheckCircleOutlineSharpIcon fontSize="large" style={{color: green[500] }} />;
        else if (outcome.toLowerCase() === "failed")
            return <HighlightOffSharpIcon fontSize="large" style={{color: red[500] }} />;
        else if (outcome.toLowerCase() === "cancelled")
            return <PanToolSharpIcon fontSize="large" style={{color: grey[500] }} />;
        else
            return <LoopSharpIcon fontSize="large" color="secondary" />;
    }

    function jobDetailsExpansionPanel(job: IJob) {
        const inputDetails: IInputDetails[] = job?.inputs ? job?.inputs : [];
        return (
            <>
                <Accordion defaultExpanded>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography variant="h6">Results and Job Settings</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {resultDetailsDisplay(props.job)}
                        {jobSettingsDisplay(job)}
                        {inputInfoDisplay(job)}
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography variant="h6">Running tasks</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {runningTasks(job)}
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography variant="h6">Failed tasks</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {failedTasks(job)}
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography variant="h6">Step Details</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {stepDisplayTable(job)}
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="h6">Input Details</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {inputDetailsList(inputDetails)}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </>
        )
    }

    function runningTasks(job: IJob) {
        return (<span><Button variant="contained" color="secondary" disableElevation size="small" startIcon={<GetAppSharpIcon />} onClick={(event) => getRunningTasks(event)}>
            Get running tasks
        </Button>&nbsp;{runningTasksInfo}</span>)
    }

    function failedTasks(job: IJob) {
        let message: string | JSX.Element;

        if (typeof(failedTasksInfo) === 'string')
            message = failedTasksInfo
        else {
            message = (
                <span>
                    {failedTasksInfo.map(t =>
                        <Button variant="contained" color="secondary" disableElevation size="small" startIcon={<GetAppSharpIcon />} onClick={(event) => handleDownloadTaskLogs(t.substring(0, t.indexOf(':')))}>
                            {t}
                        </Button>
                    )}
                </span>);
        }

        return (<span><Button variant="contained" color="secondary" disableElevation size="small" startIcon={<GetAppSharpIcon />} onClick={(event) => getFailedTasks(event)}>
            Get failed tasks
        </Button>&nbsp;{message}</span>)
    }

    function stepDisplayTable(job: IJob) {
        return (
            <TableContainer >
                <Table size="small" >
                    <TableBody>
                        {jobStepsFetch.data?.steps.map((jobStep, index) =>
                            <StyledTableRow
                                key={index}
                                selected={selected === jobStep}
                                onClick={(event) => handleClick(event, jobStep)}
                            >
                                <StyledTableCell style={{ width: 256 }}>
                                    {stepDisplay(jobStep, index === jobStepsFetch.data?.activeIndex)}
                                </StyledTableCell>
                                <StyledTableCell>
                                    <LinearProgress variant="determinate" color="secondary" value={jobStep.percentage} />
                                </StyledTableCell>
                                <StyledTableCell style={{ width: 600 }}>
                                    {}
                                </StyledTableCell>
                            </StyledTableRow >
                        )}
                    </TableBody>
                </Table>
                {selected ? <JobStepDetails job={props.job} stepName={selected.name} /> : <div></div>}
            </TableContainer>
        )
    }

    function stepDisplay(step: IJobStep, isActive: boolean) {
        const stepText = `${step.name} (${step.taskCount ?? '?'})`;
        if (isActive)
            return <strong>{stepText}</strong>;
        return stepText;
    }

    return (
        <StyledDivSpacing >
            <TableContainer >
                <Table size="small" style={{ width: "auto", tableLayout: "auto" }}>
                    <StyledTableCell  >
                        {passCheckDisplay(props.job)}
                    </StyledTableCell>
                    <StyledTableCell  >
                        <Typography noWrap variant="h4">
                            {props.job.name}
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell   >
                        <Typography>
                            <BorderLinearProgress variant="determinate" color="secondary" value={jobStepsFetch.data.percentage} />
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell  >
                        <Typography noWrap variant="h5" style={{ width: 90 }}>
                            {jobStepsFetch.data.percentage}%
                        </Typography>
                    </StyledTableCell>
                </Table>
                <Table size="small">
                    <StyledTableCell  >
                        {jobInfoHeader(props.job)}
                    </StyledTableCell>
                    <StyledTableCell  >
                        {submissionDetailsDisplay(props.job)}
                    </StyledTableCell>
                </Table>
                {jobDetailsExpansionPanel(props.job)}
            </TableContainer>
        </StyledDivSpacing>
    );
}

export default function JobDetails(props: any) {
    const jobId = useParams().id as unknown as string;

    const jobFetch = useFetchApi<IJob>(`${window.location.origin}/api/v2/jobs/${jobId}`);

    React.useEffect(() => {
        jobFetch.run();
    }, []);

    if (jobFetch.isFetching)
        return (<CenteredProgress />);

    if (jobFetch.error)
        return (<div>{`${jobFetch.error}`}</div>);

    if (!jobFetch.data)
        return (<div>{`Job ${jobId} not found`}</div>);

    return (
        <StyledDivRoot>
             <StyledDivSpacing>
                  <JobInfoDisplay job={jobFetch.data} />
             </StyledDivSpacing>
        </StyledDivRoot>
    );
}
