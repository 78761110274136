import React, { useState } from 'react';
import ClusterMap from './ClusterMap';
import { Grid,Paper } from '@mui/material';
import DashboardJobsOverview from './DashboardJobsOverview';
import JobTypeOutcomeStatistics from './JobTypeOutcomeStatistics';
import { styled } from '@mui/system';


const StyledDiv = styled('div')(()=> ({
    flexGrow: 1,
}))

const StyledGrid = styled(Grid)((props)=> ({
    padding: props.theme.spacing(0.5),
    height: "100%",
}))

function Dashboard() {

  return (
    <StyledDiv>
        <Grid container direction="row">            
            <StyledGrid item xs={12} sm={12} md={12} lg={8} >
                <DashboardJobsOverview dataUrl= {window.location.origin + '/api/v2/jobs/report?'} actionUrl={window.location.origin + '/api/v2/jobs/'}/>
            </StyledGrid>
            <Grid item xs={12} sm={12} md={12} lg={4} sx={{pl:0.3}}>
                <Grid container>
                    <StyledGrid item xs={12} sm={12} md={12} lg={12}>
                          <JobTypeOutcomeStatistics/>
                    </StyledGrid>
                    <StyledGrid item xs={12} sm={12} md={12} lg={12}>
                           <ClusterMap />
                    </StyledGrid>
                </Grid>
            </Grid>
        </Grid>
    </StyledDiv >
);
};

export default Dashboard;
