import * as React from 'react';
import MaterialTable from "@material-table/core";
import HoursTimespanSelector, { HoursTimespan } from './HoursTimespanSelector';
import { IJob, useJobActions, useJobReport } from '../Model/Job';
import { createDateColumn } from '../utils/DateUtils';
import useInterval from '../utils/UseInterval';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { JobLink } from './JobsOverview';
import { Typography,Tooltip } from '@mui/material';


interface IFilterProps{
    jobFilter?: string;
    toggleTabRefresh: boolean;
}

export function EmailEllipsis(props:{Email:string}) { 
    return  <Tooltip title={props.Email} >
               <Typography style={{textOverflow : "ellipsis",overflow: 'hidden',maxHeight:'45px',fontSize:'0.875rem',overflowWrap:"break-word",WebkitLineClamp:2,WebkitBoxOrient:"vertical",display:"-webkit-box"}}> {props.Email} </Typography>
            </Tooltip>;
}


export default function CompletedJobsFilter(props:IFilterProps) {

    const jobFilter= props.jobFilter ? props.jobFilter : 'state=Completed';
    const jobReport = useJobReport(jobFilter);
    const jobActions = useJobActions();
    const [autoRefresh, setAutoRefresh] = React.useState(props.toggleTabRefresh);
    
    
    function onTimespanChanged(newTimespan: HoursTimespan) {
        jobReport.SetHoursTimespan(newTimespan);
    }

    function toggleAutoRefresh() {
        if (autoRefresh) {
            setAutoRefresh(false);
        }
        else {
            setAutoRefresh(true);
            jobReport.refresh();
        }
    }

    React.useEffect(() => {
        if(autoRefresh===false )   //Trick to get refresh just by one variable on tab change
        {
            jobReport.refresh();
        }
        setAutoRefresh(props.toggleTabRefresh)

    },[props.toggleTabRefresh]);

    useInterval(() => {
        if (jobReport.error) {
            setAutoRefresh(false);
            return;
        }

        jobReport.refresh()
    }, autoRefresh ? 10000 : null);

    
    return (
        <div>
            
            <MaterialTable<IJob>
                title={<HoursTimespanSelector default={jobReport.hoursTimeSpan}  onValueChanged={onTimespanChanged} />}
                isLoading={jobReport.fetchCount === 0} // Avoid flicker on auto refresh and show loading only on first attempt
                columns={[
                    { title: "Name", field: "name", render: rowData => <JobLink job={rowData} /> },
                    { title: "Type", field: "type" },
                    { title: "Email", field: "userDetails.email" as any,cellStyle: { maxWidth:'150px'}, headerStyle:{maxWidth:'150px'} ,render: rowData => <EmailEllipsis Email={rowData.userDetails.email} />},
                    createDateColumn("Start", "executionInformation.startTime"),
                    createDateColumn("End", "executionInformation.endTime", 'desc'),
                    { title: "Location", field: "dataCenter.location"},
                ]}
                data={jobReport.data ?? []}
                actions={[
                    {
                        icon: () => autoRefresh ? <PauseCircleOutlineIcon /> : <PlayCircleOutlineIcon />,
                        tooltip: 'Auto Refresh',
                        isFreeAction: true,
                        onClick: () => toggleAutoRefresh()
                    },
                    rowData => ({
                        tooltip: 'Download logs',
                        icon: 'get_app',
                        disabled: rowData.state === "New",
                        onClick: (event, rowData) => jobActions.downloadLogs(rowData)
                    }),
                    

                ]}
                options={{
                    pageSize: 10,
                    search: true,
                    headerStyle: { backgroundColor: jobReport.error ? '#FF0000' : '#9BA5AE', color: '#000000' },
                    padding: 'dense',
                    maxBodyHeight: "63vh",
                    rowStyle: {
                        fontSize: "0.875rem",
                    },
                    sorting: false
                }}
            />
           
        </div>
    );

}


